/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import useAxios from 'axios-hooks';

const AcademicYearFilter = ({
  selectedYear,
  setSelectedYear,
  selectedSchoolId
}) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [count, setCount] = useState(0);

  const [{ data: yearsData, loading: loadingYears }, getSchoolAcademicYears] =
    useAxios(
      {
        url: `${process.env.REACT_APP_BASE_URL}api/v2/schools/${selectedSchoolId}/periods`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
        }
      },
      {
        manual: true
      }
    );

  useEffect(() => {
    if (selectedSchoolId) {
      getSchoolAcademicYears();
    }
  }, [selectedSchoolId]);

  useEffect(() => {
    if (yearsData) {
      const yearsOptions = [];

      yearsData.forEach((year) =>
        yearsOptions.push({ value: year.id, label: year.label })
      );
      setSelectOptions(yearsOptions);

      setCount((c) => c + 1);
    }
  }, [yearsData]);

  return (
    <div
      className="form-group do-float"
      key={count}
      css={css`
        .form-type-material .bootstrap-select .dropdown-toggle:focus {
          background-color: transparent !important;
        }
      `}
    >
      <select
        type="text"
        name="yearId"
        id="yearId"
        className="form-control bg-transparent"
        data-provide="selectpicker"
        onChange={(e) => setSelectedYear(e.target.value)}
        value={selectedYear}
        disabled={loadingYears}
      >
        <option value="" disabled>
          Nothing selected
        </option>

        {selectOptions.map((year) => (
          <option
            key={year.value}
            value={year.value}
            className={year.value ? '' : 'd-none'}
          >
            {year.label}
          </option>
        ))}
      </select>
      <label htmlFor="yearId" className="label-floated">
        Academic Year
      </label>
    </div>
  );
};

export default AcademicYearFilter;
